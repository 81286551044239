@keyframes openOverlay {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.overlay {
  animation: openOverlay 0.3s;
}
